import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import DisbursementInfo from './DisbursementInfo';
import InProgress from '../forms/InProgress';
import Card from '@mui/material/Card';
import ReportsEmptyPage from './ReportsEmptyImage';
import ReportsNotFoundData from './ReportNotFoundDataPage';

import ReportsFields from './ReportsFields';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { IsRefundDisbursement } from '../constants/Disbursement';

const ControlledTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow
		{...props} classes={{ popper: className }}
		disableFocusListener
		disableHoverListener
		disableTouchListener
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 25
	},
}));

const useStyles = makeStyles((theme) => ({
	item: {
		margin: '3% 2% 2% 2%',
		border: '1px solid #AFCFF2',
		borderRadius: '20px',
		padding: '1% 2% 1% 2%',
	},
	totalTransactions: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #0DC0D6',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalFeeTransactions: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #AF50C0',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalDisbursement: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #5DB761',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalRefund: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #E14428',
		paddingTop: '10px',
		borderRadius: '8px',

	},
	apply: {
		backgroundColor: 'Lightblue',
		marginRight: '4%'
	},
	commonMargin: {
		marginBottom: '2%',
		marginTop: '2%'
	},
	tooltip: {
		color: '#747779',
		fontFamily: 'Roboto',
		fontWeight: '400px',
		fontSize: '16px',
		lineHeight: '14px',
		textTransform: 'none',
		marginBottom: '1%'
	},
	runReports: {
		backgroundColor: '#1070D7',
		borderRadius: 4,
		fontFamily: 'Roboto',
		fontWeight: 700,
		fontSize: 16,
		lineHeight: '14px',
		color: '#FFFFFF',
		textTransform: 'none',
		padding: 10
	},
	expandAll: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '14px',
		textDecorationLine: 'underline',
		color: '#1070D7',
		marginTop: '3%',
		marginBottom: '0.5%'
	},
	total: {
		fontFamily: 'Roboto',
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '12px',
		color: '#747779',
		margin: 'auto',
		textAlign: 'center'
	}

}));

const Disbursement = ({
	value = '',
	setValue = () => {},
	getMerchantReports = () => {},
	merchantId = '',
	disbursementsInfo = [],
	setClientValue = () => {},
	clientValue = '',
	showReports = false,
	isLoading = false,
	merchantInfo = {},
	openSelectTooltip = false,
	setOpenSelectTooltip = () => {},
	setPrintToolTip = () => {},
	disbursementFeatureFlagInfo = {}

}) => {

	const [ expandAll, setExpandAll ] = useState(false);
	const [ disbursementRow, setDisbursementRow ] = useState(false);
	const [ expandAllToolTip, setExpandAllToolTip ] = useState(false);
	const disbursementRefund = _.sumBy(disbursementsInfo, 'refund') ?? 0;
	const disbursementFees = _.sumBy(disbursementsInfo, 'fees') ?? 0;
	const disbursementReturn = _.sumBy(disbursementsInfo, 'return') ?? 0;
	const disbursementDispute = _.sumBy(disbursementsInfo, 'dispute') ?? 0;

	const amount = _.sumBy(disbursementsInfo, (disbursementRecord) => {
		return _.sumBy(disbursementRecord.disbursementEntries, (currentRecord) => {
			if (IsRefundDisbursement(currentRecord.event)) {
				return 0;
			}
			return parseFloat(currentRecord.transactionInfo?.originalAmount ?? 0) 
		})
	});

	const disbursementSurcharge = _.sumBy(disbursementsInfo, (disbursementRecord) => {
		return _.sumBy(disbursementRecord.disbursementEntries, (currentRecord) => {
			if (IsRefundDisbursement(currentRecord.event)) {
				return 0;
			}
			return parseFloat(currentRecord.transactionInfo.surcharge ?? 0)
		})
	});

	const classes = useStyles();
	const handleTooltipClose = () => {
		setExpandAllToolTip(false);
	};
	
	const gettingReportData = (isLoading) => {
		if (isLoading) {
			return (<>
				<InProgress/>
				<Grid container justifyContent='center' style={{ marginTop: '20%', marginBottom: 'auto', fontWeight: 'bold' }}>
					Getting Report Data...
				</Grid>
			</>
			);
		}
		
	};

	return (
		<>
			<ReportsFields
				openSelectTooltip={openSelectTooltip}
				setOpenSelectTooltip={setOpenSelectTooltip}
				value={value}
				setValue={setValue}
				getMerchantReports={getMerchantReports}
				merchantId={merchantId}
				disbursementsInfo={disbursementsInfo}
				setClientValue={setClientValue}
				clientValue={clientValue}
				showReports={showReports}
				isLoading={isLoading}
				merchantInfo={merchantInfo}
				setDisbursementRow={setDisbursementRow}
			/>
			{isLoading ? gettingReportData(isLoading) :
				(showReports && disbursementsInfo.length && !isLoading) ? <>
					<Grid container style={{ borderBottom: '1px solid Lightgray' }} direction='row'>
						<Grid item lg={4} xs={12} md={1} className={classes.expandAll} onClick={() => setExpandAll(!expandAll)}>
							<ControlledTooltip
								title={
									<>
										<Typography>
                        Expand and collapse rows at once.
										</Typography>
										<Grid container direction='row' style={{ marginTop: '1.5%' }}>
											<Grid item sm={6} style={{ marginTop: '1.5%' }}> 4 of 5</Grid>
											<Grid item sm={6}>
												<Grid container direction='row' justifyContent='flex-end'>
													<Button
														style={{ fontFamily: 'Roboto',
															fontWeight: 700,
															color: '#CDDBFF',
															textTransform: 'none'
														}}
														onClick={handleTooltipClose}
													>
                                    Skip
													</Button>
													<Button style={{
														backgroundColor: '#CDDBFF',
														borderRadius: 4,
														fontFamily: 'Roboto',
														fontWeight: 700,
														color: '#151D33',
														textTransform: 'none'
													}}
													onClick={(e) => {
														handleTooltipClose();
														setPrintToolTip(true);
														e.stopPropagation();
														e.preventDefault();
													}
													}
													>
                                    Next
													</Button>
												</Grid>
											</Grid>

										</Grid>
									</>
								}
								open={expandAllToolTip}
								onClose={handleTooltipClose}
							>
								{!expandAll ? <span>Expand All</span> : <span>Collapse All </span>}
							</ControlledTooltip>
						</Grid>
						<Grid item lg={8} xs={12} md={11} sm={12}>
							<Grid container justifyContent={'flex-end'}>
								<Grid className={classes.totalTransactions} lg={2} xs={12} sm={3} md={3}>
									<Grid container direction='column'>
										<Grid className={classes.total} >
                            No. of Disbursements
										</Grid>
										<Grid container direction='row' justifyContent={'center'} >
											<Grid style={{ color: '#083F7A', textAlign: 'right' }} >
												{disbursementsInfo.length}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid className={classes.totalFeeTransactions} lg={2} xs={12} sm={3} md={3}>
									<Grid container direction='column'>
										<Grid className={classes.total}>
											Total Fee
										</Grid>
										<Grid container direction='row' justifyContent={'center'} >
											<Grid style={{ color: '#083F7A', textAlign: 'right' }} >
												{`-${Math.abs((disbursementFees / 100) * 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid className={classes.totalRefund} lg={2} xs={12} sm={4} md={3}>
									<Grid container direction='column'>
										<Grid className={classes.total}>
                            Total Deducted
										</Grid>
										<Grid container direction='row' justifyContent={'center'}>
											<Grid style={{ color: '#083F7A', textAlign: 'right' }} >
												{`-${Math.abs((disbursementRefund + disbursementReturn + disbursementDispute)  / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid className={classes.totalDisbursement} lg={2} xs={12} sm={4} md={3}>
									<Grid container direction='column' justifyContent={'flex-end'}>
										<Grid className={classes.total}>
                            Net Total
										</Grid>
										<Grid container direction='row' justifyContent={'center'} style={{ color: '#083F7A', textAlign: 'right' }}>
											<Grid >
												{(amount + disbursementSurcharge - disbursementFees).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Card style={{
						maxWidth: '100%',
						overflow: 'auto',
						maxHeight: '63vh' }}>
						{_.map(disbursementsInfo, (disbursementInfo, index) =>{
							return <DisbursementInfo
								expandAll={expandAll}
								disbursementsEntriesInfo={disbursementInfo.disbursementEntries}
								key={index}
								processed={disbursementInfo.processed}
								amount={disbursementInfo.amount}
								merchantInfo={merchantInfo}
								setDisbursementRow={setDisbursementRow}
								disbursementRow={ index === 0 ? disbursementRow : false}
								setExpandAllToolTip={setExpandAllToolTip}
								refund={disbursementInfo.refund}
								returned = {disbursementInfo.return}
								chargeback = {disbursementInfo.dispute}
								fees={disbursementInfo.fees ?? 0}
								disbursementFeatureFlagInfo={disbursementFeatureFlagInfo}
							/>;

						})}
					</Card>
				</> : (!disbursementsInfo.length && showReports) ? <ReportsNotFoundData/> : <ReportsEmptyPage/>}
		</>
	);
};

Disbursement.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	getMerchantReports: PropTypes.func,
	merchantId: PropTypes.string,
	disbursementsInfo: PropTypes.array,
	setClientValue: PropTypes.func,
	clientValue: PropTypes.string,
	showReports: PropTypes.bool,
	isLoading: PropTypes.bool,
	merchantInfo: PropTypes.object,
	openSelectTooltip: PropTypes.bool,
	setOpenSelectTooltip: PropTypes.func,
	setPrintToolTip: PropTypes.func,
	disbursementFeatureFlagInfo: PropTypes.object
};
export default Disbursement;