import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import PrintIcon from '@mui/icons-material/Print';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import printJS from 'print-js';
import Disbursement from './Disbursement';
import Header from './Header';
import ReportOptions from './ReportOptions';
import { useQuery } from '../hooks/useQuery';
import { getApiUrl } from '../utils/getApiUrl';
import { getMerchantData, initializePendo } from '../services/Services';

const ControlledTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow
		{...props} classes={{ popper: className }}
		disableFocusListener
		disableHoverListener
		disableTouchListener
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 25
	},
}));

const useStyles = makeStyles((theme) => ({
	tab: {
		margin: '1%  1%  0 1%',
		color: 'red',
		fontFamily: 'Roboto',
		fontWeight: '400px',
		fontSize: '16px',
		lineHeight: '14px',
		textTransform: 'none'
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function BasicTabs() {
	const params = useParams();
	const history = useHistory();
	const query = useQuery();

	const [ tabValue, setTabValue ] = React.useState(0);
	const [ merchantId, setMerchantId ] = useState('');
	const [ value, setValue ] = React.useState('');
	const [ clientValue, setClientValue ] = React.useState('');
	const [ disbursementsInfo, setDisbursementsInfo ] = useState([]);
	const [ showReports, setShowReports ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ openSelect, setOpenSelect ] = useState(false);
	const [ displayReportOption, setDisplayReportOptions ] = React.useState(false);
	const [ savedDisbursementsInfo, setSavedDisbursementsInfo ] = useState([]);
	const [ merchantInfo, setMerchantInfo ] = useState({});
	const [ openSelectTooltip, setOpenSelectTooltip ] = useState(false);
	const [ printToolTip, setPrintToolTip ] = useState(false);
	const [ disbursementFeatureFlagInfo, setDisbursementFeatureFlagInfo ] = useState([]);

	useEffect(() => {
		if (clientValue) {
			filterTransactionsInfo(savedDisbursementsInfo, clientValue);
		} else {
			setDisbursementsInfo(savedDisbursementsInfo);
		}
	}, [ clientValue ]);

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${params.reports}`
		}
	};

	useEffect(() => {
		setMerchantInfo({
			entity: query.get('entity'),
			entityId: query.get('entityId'),
			name: query.get('name'),
		});
		setMerchantId(query.get('merchantId'));

		async function fetchMerchant(axiosConfig) {
			const customer = await getMerchantData(query.get('merchantId'), axiosConfig);
			initializePendo(customer?.application, customer?.customerId, customer?.customerName);

		}
		fetchMerchant(axiosConfig);
		getFeatureFlagInfo();

	}, [ params ]);

	const getMerchantReports = async (merchantId) => {
		try {
			setIsLoading(true);
			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/get_disbursement_from_payrix?merchantId=${merchantId}`,

				{
					startDate: _.split(moment(_.split(value, ' - ', 2)[0]).format('YYYY-MM-DD'))[0],
					endDate: _.split(moment(_.split(value, ' - ', 2)[1]).format('YYYY-MM-DD'))[0]
				},
				axiosConfig);
			if (data.code === 200) {
					setSavedDisbursementsInfo(data?.reportModel);
					if (clientValue) filterTransactionsInfo(data.reportModel, clientValue);
					else setDisbursementsInfo(data?.reportModel);
	
					setIsLoading(false);
					setShowReports(true);
			}
		} catch (err) {
			setIsLoading(false);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const getFeatureFlagInfo = async () => {
		try {
			setIsLoading(true);

			let configData = await axios.get(`${getApiUrl()}/api/config/fee_rates?merchantId=${query.get('merchantId')}`, axiosConfig);
			if (configData.data.code === 200) {
				setDisbursementFeatureFlagInfo({featureFlagValue:configData.data.isPricingChangeActive, config:configData.data.config});
				setIsLoading(false);
				setShowReports(true);
			}
		} catch (err) {
			setIsLoading(false);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const handleChange = (e, newValue) => {
		setTabValue(newValue);
	};

	const filterTransactionsInfo = (disbursements = [], clientValue) => {
		if (clientValue.length > 2) {
			const newDisbursementsInfo = _.filter(disbursements, function (item) {
				return _.includes(item?.client, clientValue);
			});
			setDisbursementsInfo(newDisbursementsInfo);
		} else {
			setDisbursementsInfo(savedDisbursementsInfo);
		}
	};

	const printReport = async () => {
		try {
			const requestData = { disbursementsInfo, merchantsInfo: merchantInfo, format: 'pdf', userSelectedOption: 'print' };
			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/get_disbursement_report`, requestData, { params: { merchantId }, ...axiosConfig });
			const printJsConfig = {
				printable: data,
				type: 'pdf',
				base64: true,
				showModal: true,
				modalMessage: 'Loading PDF...'
			};
			printJS(printJsConfig);
		} catch (error) {
			console.error('Failed to print report', error);
		}
	};

	async function downloadFile (selectedOption) {
		try {
			const requestData = { disbursementsInfo, merchantsInfo: merchantInfo, format: selectedOption, userSelectedOption: 'download' };
			const response =
                await axios.post(`${getApiUrl()}/api/merchantwebapi/get_disbursement_report`,
                	requestData,
                	{ params: { merchantId }, responseType: 'arraybuffer', ...axiosConfig }
                );
			const blobData = new Blob([ response.data ]);
			const url = window.URL.createObjectURL(blobData);
			const link = document.createElement('a');
			const fileExtension = selectedOption === 'pdf' ? '.pdf' : '.csv';
			link.href = url;
			link.setAttribute('download', (`APX Disbursement Report ${moment().format('MM-DD-YYYY')}${fileExtension}`));
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			console.error('Failed to download report', error);
		}
	}

	const onReportOptionSelect = async (selectedOption) => {
		hideReportOptionDialog();
		await downloadFile(selectedOption);
	};

	const hideReportOptionDialog = () => {
		setDisplayReportOptions(false);
	};

	const handleTooltipClose = () => {
		setPrintToolTip(false);
	};

	return (
		<div style={{ backgroundColor: 'white', height: '98vh' }}>
			<Header showReports={showReports} setOpenSelectTooltip={setOpenSelectTooltip} openSelectTooltip={openSelectTooltip}/>
			<Box sx={{ width: '100%', backgroundColor: 'white',height: 'auto' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={tabValue}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab  label='Disbursement' {...a11yProps(0)} style={ tabValue === 0 ? { fontWeight: 'bold', color: '#13191C' } : {
							margin: '0  1%  0 0',
							color: '#13191C',
							fontFamily: 'Roboto',
							fontWeight: '400px',
							fontSize: '16px',
							lineHeight: '14px',
							textTransform: 'none'
						}} />
						{/* <Tab  label='Transactions' {...a11yProps(1)} style={tabValue === 1 ? {fontWeight: 'bold', color: '#13191C'} : {*/}
						{/*    margin: '0  1%  0 0',*/}
						{/*    color: '#13191C',*/}
						{/*    fontFamily: 'Roboto',*/}
						{/*    fontWeight: '400px',*/}
						{/*    fontSize: '16px',*/}
						{/*    lineHeight: '14px',*/}
						{/*    textTransform: 'none'*/}
						{/* }} />*/}
						{/* <Tab  label='ChargeBack' {...a11yProps(2)} style={tabValue === 2 ? {fontWeight: 'bold', color: '#13191C'} : {*/}
						{/*    margin: '0  1%  0 0',*/}
						{/*    color: '#13191C',*/}
						{/*    fontFamily: 'Roboto',*/}
						{/*    fontWeight: '400px',*/}
						{/*    fontSize: '16px',*/}
						{/*    lineHeight: '14px',*/}
						{/*    textTransform: 'none'*/}
						{/* }} />*/}
						<Grid container direction='row' justifyContent='flex-end' >
							<ControlledTooltip
								title={
									<>
										<Typography>
                                            Report can be printed in PDF and exported in Excel format.
										</Typography>
										<Grid container direction='row' style={{ marginTop: '1.5%' }}>
											<Grid item sm={6} style={{ marginTop: '1.5%' }}> 5 of 5</Grid>
											<Grid item sm={6}>
												<Grid container direction='row' justifyContent='flex-end'>
													<Button id='close-report' style={{
														backgroundColor: '#CDDBFF',
														borderRadius: 4,
														fontFamily: 'Roboto',
														fontWeight: 700,
														color: '#151D33',
														textTransform: 'none'
													}}
													onClick={() => {handleTooltipClose();}}
													>
                                                        Close
													</Button>
												</Grid>
											</Grid>

										</Grid>
									</>
								}
								open={printToolTip}
								onClose={handleTooltipClose}
							>
								<span style={{ margin: '1%' }}>
									{showReports && <PrintIcon  onClick={printReport}/>}
								</span>
							</ControlledTooltip>
							{showReports && <FileDownloadOutlinedIcon style={{ margin: '1%' }} onClick={() => setDisplayReportOptions(true)} />}
						</Grid>
					</Tabs>
				</Box>
				<TabPanel value={tabValue} index={0} >
					<Disbursement
						openSelectTooltip={openSelectTooltip}
						setOpenSelectTooltip={setOpenSelectTooltip}
						value={value}
						setValue={setValue}
						getMerchantReports={getMerchantReports}
						merchantId={merchantId}
						disbursementsInfo={disbursementsInfo}
						setClientValue={setClientValue}
						clientValue={clientValue}
						showReports={showReports}
						isLoading={isLoading}
						openSelect={openSelect}
						setOpenSelect={setOpenSelect}
						merchantInfo={merchantInfo}
						setPrintToolTip={setPrintToolTip}
						disbursementFeatureFlagInfo={disbursementFeatureFlagInfo}
					/>
				</TabPanel>
				{/* <TabPanel value={tabValue} index={1}>*/}
				{/* </TabPanel>*/}
				{/* <TabPanel value={tabValue} index={2}>*/}
				{/* </TabPanel>*/}
			</Box>
			<ReportOptions
				isOpen={displayReportOption}
				onSumbit={(selectedOption) => onReportOptionSelect(selectedOption) }
				onClose={()=> hideReportOptionDialog}
			/>
		</div>
	);
}
